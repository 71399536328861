import marker from './marker'
import dotMarker from './dotMarker';

const stateOneData = [
	{
		name: 'USA',
		description: "Farmers can enroll their fields using the Climate FieldView™ platform, and receive yearly cash payouts for verified and validated climate-smart farming practices like cover cropping and practicing conservation tillage.",
		lat: 36.2425741,
		lng: -97.7464011,
		alt: 0,
		color: 'white',
		marker: marker()
	},
	{
		name: 'Brazil',
		description: "Bayer pairs farmers with a technical consultant that develops a plan and carbon map for their farm. This helps achieve the best possible soil productivity and carbon sequestration from their land.",
		lat: -13.6583823,
		lng: -53.7040651,
		alt: 0,
		color: 'white',
		marker: marker()
	},
	{
		name: 'Argentina',
		description: "Similar to Brazil, farmers in Argentina work with a technical consultant to use a digital platform that helps them adapt climate-smart practices.",
		lat: -37.0181021,
		lng: -65.649189,
		alt: 0,
		color: 'white',
		marker: marker()
	},
	{
		name: 'Europe',
		description: "Our Carbon Initiative is expanding to France, Spain, Belgium, Denmark, and Germany, as well as a new pilot program in the Ukraine and United Kingdom.",
		lat: 48.1243306,
		lng: 20.1224934,
		alt: 0,
		color: 'white',
		marker: marker()
	}
]

const N = 15;
const stateTwoData = [...Array(N).keys()].map(() => ({
	lat: 0,
	lng: 0,
	alt: -0.998,
	radius: 20,
	marker: dotMarker()
}));

const stateThreeData = [
	{
		name: 'India',
		description: 'FarmRise™, a free agriculture app developed by Bayer provides real-time weather and market pricing information, as well as expert agronomic advice to smallholder farmers in India.'
	},
	{
		name: 'China',
		description: 'In China, and coming soon to other countries across Asia, we’re working to develop a trackable product monitoring and service system which will improve transparency and responsiveness of the supply chain for smallholder farmers.'
	},
	// {
	// 	name: 'Japan',
	// 	description: 'In Japan, we’ve partnered with XAG — a leading agriculture drone provider — to enhance precision and reduce Japanese farmers’ pesticide use by 30% and water use by 90%.'
	// }
]


const airportParse = ([
	airportId,
	name,
	city,
	country,
	iata,
	icao,
	lat,
	lng,
	alt,
	timezone,
	dst,
	tz,
	type,
	source,
]) => ({
	country,
	iata,
	lat,
	lng,
	alt,
	timezone,
	dst,
	tz,
	type,
	source,
});
const routeParse = ([
	airline,
	airlineId,
	srcIata,
	srcAirportId,
	dstIata,
	dstAirportId,
	codeshare,
	stops,
	equipment,
]) => ({
	airline,
	airlineId,
	srcIata,
	srcAirportId,
	dstIata,
	dstAirportId,
	codeshare,
	stops,
	equipment,
});

export { airportParse, routeParse, stateOneData, stateTwoData, stateThreeData };
