const reportWebVitals = onPerfEntry => {
	// console.log(onPerfEntry);

  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
			getCLS(console.log);
			getFID(console.log);
			getFCP(console.log);
			getLCP(console.log);
			getTTFB(console.log);

    });
  }
};

export default reportWebVitals;
