import { Vector3 } from 'three'

const store = {
	step: 0,
	finalSteps: [2],
	data: null,
	satellite: null,
	camPos: new Vector3(0, 0, 0),
	lat: 0,
	lng: 0,
	fullPage: null
}

export default store
