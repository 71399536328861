import React from 'react';
import store from '../state'
const ScrollIcon = ({ activeIndex }) => {
	const activePanel = parseInt(activeIndex);

	const handleUp = () => {
		store.fullPage.moveSectionUp()
	}

	const handleDown = () => {
		store.fullPage.moveSectionDown()
	}

	return (
		<div>
			<div className="fixed-scroll-button up" style={{ background: 'url(scroll-01.png) no-repeat center center / 100%' }} data-active={activePanel} onClick={(e) => handleUp()} />
			<div className="fixed-scroll-button down" style={{ background: 'url(scroll-02.png) no-repeat center center / 100%' }} data-active={activePanel} onClick={(e) => handleDown()}/>
		</div>
	);
}
export default ScrollIcon;
