import * as THREE from 'three'

const dotMarker = () => {
	var height = Math.random() * 40;
	// console.log(height)

	const _marker = new THREE.Object3D();

	// const cylinder = new THREE.Mesh(
	// 	new THREE.CylinderGeometry(0.2, 0.2, height, 32),
	// 	new THREE.MeshLambertMaterial({ color: 'white' })
	// );
	const sphere = new THREE.Mesh(
		new THREE.SphereBufferGeometry(0.6),
		new THREE.MeshLambertMaterial({ color: 'white' })
	);
	// const hex = new THREE.Mesh(
	// 	new THREE.CylinderGeometry(4, 4, 1, 6, 1, true),
	// 	new THREE.MeshLambertMaterial({ color: 'white', depthWrite: true, depthTest: true, side: THREE.DoubleSide })
	// );
	// hex.rotateX(Math.PI / 2);
	sphere.position.y = -height - 4
	// hex.position.y = -54
	// cylinder.position.y = -height/2
	_marker.add(sphere)

	return _marker;
}

export default dotMarker;
