import * as THREE from 'three'

const lineMarker = (height) => {
	var _height = height ? height : Math.random() * 60
	const _marker = new THREE.Object3D();
	const cylinder = new THREE.Mesh(
		new THREE.CylinderGeometry(0.4, 0.4, _height, 6),
		new THREE.MeshLambertMaterial({ color: 'rgb(82,174,252)' })
	);

	cylinder.position.y = -height / 2 + 20
	_marker.add(cylinder)

	return _marker;
}

export default lineMarker;
