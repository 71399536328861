import React, { useState, useEffect, useRef } from "react";
import indexBy from "index-array-by";
import Globe from "react-globe.gl";
import * as THREE from 'three'
import { stateOneData, airportParse, routeParse, stateThreeData } from '../content'
import store from '../state'
import marker from '../marker'
import lineMarker from '../lineMarker'
import * as d3 from 'd3'
// import Stats from 'stats.js'
var d3DSV = require("d3-dsv");
var simplify = require('simplify-geojson');
// var stats = new Stats();
// stats.showPanel(1);
// document.body.appendChild(stats.dom);

function GlobeOne({ index }) {
	const globeEl = useRef();
	const [routes, setRoutes] = useState([]);
	const [pathData, setPathData] = useState([]);
	const [pathTwoData, setPathTwoData] = useState([]);
	const [step, setStep] = useState(0);
	const [finalSteps, setFinalSteps] = useState(0);
	// const [lat, setLat] = useState(0);
	// const [lng, setLng] = useState(0);
	const [animateArc, setAnimateArc] = useState(false);
	const [s1Countries, setS1Countries] = useState({ features: [] });
	const [s2Countries, setS2Countries] = useState({ features: [] });
	const [s3Countries, setS3Countries] = useState({ features: [] });
	const fullPage = store.fullPage;
	const [allCountries, setAllCountries] = useState({ features: [] });
	const [s23Countries, set23Countries] = useState({ features: [] });
	const [s24Countries, set24Countries] = useState({ features: [] });
	const [s34Countries, set34Countries] = useState({ features: [] });

	var tempData = [], tempDataTwo = [];
	var tempCountries = [], tempCountriesTwo = [], tempCountriesThree = []

	const randomBoundingBoxCoordinates = (boundingBox) => {
		const randomLongitude = d3.randomUniform(
			boundingBox[0][0],
			boundingBox[1][0] + 360 * (boundingBox[1][0] < boundingBox[0][0])
		)
		const randomLatitude = d3.randomUniform(boundingBox[0][1], boundingBox[1][1])
		return () => [randomLongitude(), randomLatitude()]
	}

	const randomFeatureCoordinates = (feature) => {
		const featureBoundingBox = d3.geoBounds(feature)
		const randomCoordinates = randomBoundingBoxCoordinates(featureBoundingBox)
		return () => {
			let p
			do {
				p = randomCoordinates()
			} while (!d3.geoContains(feature, p))
			return p
		}
	}

	const generatePathData = (features, num, alt) => {
		let s1PathData = []
		var _num;
		var _alt = alt;
		for (let index = 0; index < features.length; index++) {
			const feature = features[index];
			if (feature.properties.NAME === "United States of America") {
				// _num = 35
				_num = num
			} else {
				_num = num
			}

			for (let i = 0; i < _num; i++) {
				const randFeat = randomFeatureCoordinates(feature)
				var latLng = randFeat()
				let thisFeaturesData = {
					lat: latLng[1],
					lng: latLng[0],
					alt: Math.random() * _alt,
					marker: marker(),
					name: feature.properties.NAME
				}
				s1PathData.push(thisFeaturesData)
			}
		}
		return s1PathData;
	}

	useEffect(() => {
		var s1PathData;
		// load data
		fetch('ArgentineBrazilUSEurope.geojson').then(res => res.json()).then((res)=> {
			var simplified = simplify(res, 0.06);
			setS1Countries(simplified)
			s1PathData = generatePathData(res.features, 6, 0)
			const array3 = stateOneData.concat(s1PathData)
			tempData = array3;
			tempCountries = simplified.features;
		});

	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		console.log(fullPage)
		if (fullPage) {
			fullPage.setAllowScrolling(false)
			fullPage.setKeyboardScrolling(true)
		}
	}, [fullPage]);

	useEffect(() => {
		var s2PathData;
		// load data
		fetch('stateTwo.geojson').then(res => res.json()).then((res) => {
			var simplified = simplify(res, 0.2)
			setS2Countries(simplified)
			s2PathData = generatePathData(res.features, 8, 0.1);
			tempDataTwo = s2PathData
			tempCountriesTwo = simplified.features;
			// setPathTwoData(s2PathData)
		});
	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		globeEl.current.controls().enableZoom = false;
		if (window.innerWidth < 600) {
			globeEl.current.controls().autoRotate = false;
		} else {
			globeEl.current.controls().autoRotate = true;
			globeEl.current.controls().autoRotateSpeed = 1;
		}

		// load data
		fetch('indiaChinaJapan.geojson').then(res => res.json()).then((res)=>{
			var simplified = simplify(res, 0.2)
			setS3Countries(simplified)
			tempCountriesThree = simplified.features;
		});
	}, []);

	useEffect(() => {
		Promise.all([
			fetch(
				"https://raw.githubusercontent.com/jpatokal/openflights/master/data/airports.dat"
			)
				.then((res) => res.text())
				.then((d) => d3DSV.csvParseRows(d, airportParse)),
			fetch(
				"https://raw.githubusercontent.com/jpatokal/openflights/master/data/routes.dat"
			)
				.then((res) => res.text())
				.then((d) => d3DSV.csvParseRows(d, routeParse)),
		]).then(([airports, routes]) => {
			const byIata = indexBy(airports, "iata", false);
			var final_routes = []
			// var final_airports = []

			for (let index = 0; index < stateThreeData.length; index++) {
				const country = stateThreeData[index];

				// const filteredAirports = airports.filter((d) => d.country === country.name);

				const filteredRoutes = routes
					.filter(
						(d) =>
							byIata.hasOwnProperty(d.srcIata) && byIata.hasOwnProperty(d.dstIata)
					)
					.filter((d) => d.stops === "0")
					.map((d) =>
						Object.assign(d, {
							srcAirport: byIata[d.srcIata],
							dstAirport: byIata[d.dstIata],
							description: d.description
						})
					)
					.filter((d) => d.srcAirport.country === country.name && d.dstAirport.country === country.name);
					const sliceRoutes = filteredRoutes.slice(0, 25);
					// const sliceAirports = filteredAirports.slice(0, 15);
					final_routes.push(...sliceRoutes)
					// final_airports.push(...sliceAirports)
			}

			// setAirports(final_airports);
			setRoutes(final_routes);
		});

		// globeEl.current.controls().autoRotate = true
		animationLoop()

		// window.addEventListener('resize', e => {
		// 	// globeEl.current.renderer().setSize(window.innerWidth, window.innerHeight)
		// 	// console.log()
		// 	const camera = globeEl.current.camera()
		// 	// const scene = globeEl.current.scene()
		// 	const renderer = globeEl.current.renderer()
		// 	// globeEl.current.renderer().render(scene, camera);
		// 	if (renderer && window && camera) {
		// 		camera.aspect = window.innerWidth / window.innerHeight;
		// 		camera.updateProjectionMatrix();
		// 		renderer.setSize(window.innerWidth, window.innerHeight);
		// 	}
		// 	if (globeEl.current && window && camera) {
		// 		camera.aspect = window.innerWidth / window.innerHeight;
		// 		camera.updateProjectionMatrix();
		// 		globeEl.current.width = window.innerWidth;
		// 		globeEl.current.height = window.innerHeight
		// 	}
		// })
	}, []);

	// eslint-disable-next-line
	useEffect(() => {
		setStep(store.step)
		var sortedSteps = store.finalSteps.sort();
		setFinalSteps(sortedSteps)

		if (window.innerWidth >= 600) {
			if (store.step === 0) {
				globeEl.current.pointOfView({ lat: 37.0902, lng: -95.7129, altitude: 2 });
			} else if (store.step === 2 || store.step === 5) {
				globeEl.current.pointOfView({ altitude: 3 });
			} else if (store.step === 3) {
				globeEl.current.pointOfView({ altitude: 3 });
			} else if (store.step === 4) {
				globeEl.current.pointOfView({ lat: 35.8617, lng: 104.1954, altitude: 3 });
			} else {
				globeEl.current.pointOfView({ altitude: 3 });
			}
		} else {
			globeEl.current.pointOfView({ altitude: 2 });
		}

		if (store.step === 4) {
			setAnimateArc(true)
		} else {
			setAnimateArc(false)
		}

	})

	// const start = Date.now()
	const animationLoop = () => {
		// setLat(parseFloat(globeEl.current.pointOfView().lat).toFixed(4))
		// setLng(parseFloat(globeEl.current.pointOfView().lng).toFixed(4))

		// stats.begin();

		if (tempData.length > 0 && store.step === 2) {
			tempData.forEach(d => {
				d.alt -= 0.002
				d.alt = d.alt <= -0.5 ? 0 : d.alt
				return d.alt
			});
			setPathData(tempData.slice());
		}

		if (tempDataTwo.length > 0 && store.step === 3) {
			tempDataTwo.forEach(d => {
				d.alt += (0.002 * Math.random())
				d.alt = d.alt > 0 ? -0.5 * Math.random() : d.alt
				return d.alt
			});
			setPathTwoData(tempDataTwo.slice());
		}

		if (store.step === 5) {
			var sortedSteps = store.finalSteps.sort();
			setFinalSteps(sortedSteps);
			setAllCountries(tempCountries.concat(tempCountriesTwo, tempCountriesThree));
			set23Countries(tempCountries.concat(tempCountriesTwo));
			set24Countries(tempCountries.concat(tempCountriesThree));
			set34Countries(tempCountriesTwo.concat(tempCountriesThree));
		};

		// stats.end();

		requestAnimationFrame(animationLoop);
	}

	return (
		<>
			{/* {index === 2 || index === 3 || index === 4 ? <div className="lat">{lat >= 0 ? lat + '°N' : -lat + '°S' }</div> : ''}
			{index === 2 || index === 3 || index === 4 ? <div className="lng">{lng >= 0 ? lng + '°E' : -lng + '°W' }</div> : ''} */}
			<div className="globe-wrap" data-active={index} data-index={step}>
				<Globe
					ref={globeEl}
					animateIn={true}
					width={window.innerWidth}
					height={window.innerWidth < 600 ? window.innerHeight * 0.5 : window.innerHeight }
					globeImageUrl={'GettyImages-1092095756.jpg'}
					bumpImageUrl={'//unpkg.com/three-globe/example/img/earth-topology.png'}
					lineHoverPrecision={0}
					polygonsData={step === 4 || (step === 5 && JSON.stringify(finalSteps) === JSON.stringify([4])) ? s3Countries.features : step === 2 || (step === 5 && JSON.stringify(finalSteps) === JSON.stringify([2])) ? s1Countries.features : step === 3 || (step === 5 && JSON.stringify(finalSteps) === JSON.stringify([3])) ? s2Countries.features : step === 5 && JSON.stringify(finalSteps) === JSON.stringify([2, 3]) ? s23Countries : step === 5 && JSON.stringify(finalSteps) === JSON.stringify([2, 4]) ? s24Countries : step === 5 && JSON.stringify(finalSteps) === JSON.stringify([3, 4]) ? s34Countries : step === 5 && JSON.stringify(finalSteps) === JSON.stringify([2, 3, 4]) ? allCountries : undefined }
					polygonAltitude={0.01}
					polygonCapColor={() => 'rgba(255,255,0, 0.6)'}
					polygonSideColor={() => 'rgba(0, 100, 0, 0)'}
					polygonStrokeColor={() => 'rgba(0, 0, 0, 0)'}
					polygonLabel={({ properties: d }) => {
						return d.DESCRIPTION ? `<p>${d.DESCRIPTION}</p>` : undefined
					}}
					polygonsTransitionDuration={300}
					backgroundColor={'rgba(255,255,255,0)'}
					arcsData={step === 4 ? routes : undefined}
					arcStartLat={(d) => +d.srcAirport.lat}
					arcStartLng={(d) => +d.srcAirport.lng}
					arcEndLat={(d) => +d.dstAirport.lat}
					arcEndLng={(d) => +d.dstAirport.lng}
					arcDashLength={step === 4 ? 0.5 : 0}
					arcDashGap={0.5}
					arcLabel={(d) => {
						var description = stateThreeData[0].description;
						if (d.srcAirport.country === "China") {
							description = stateThreeData[1].description;
						} else if (d.srcAirport.country === "Japan") {
							description = stateThreeData[2].description;
						}
						return `<p>${description}</p>`;
					}}
					arcStroke={step === 4 ? 0.5 : 0}
					arcDashInitialGap={() => Math.random()}
					arcDashAnimateTime={animateArc ? 4000 : 0}
					atmosphereColor={'rgba(0,0,0,1)'}
					arcColor={(d) => [
						`rgba(5, 92, 121, 1)`,
						`rgba(255,255,0, 1)`,
					]}
					arcAltitude={()=>step === 4 ? Math.random() * 0.25 : -1}
					arcsTransitionDuration={animateArc ? 400 : 0}
					arcCurveResolution={16}
					customLayerLabel={(d) => {
						var description = null;
						if (step === 3) return undefined;

						if (d.name === ("United States of America" || "USA")) {
							description = stateOneData[0].description;
						} else if (d.name === "Brazil") {
							description = stateOneData[1].description;
						} else if (d.name === "Argentina") {
							description = stateOneData[2].description;
						} else if (d.name === ("Europe" || undefined)) {
							description = stateOneData[3].description;
						}
						return description ? `<p>${description}</p>` : null;
					}}
					customLayerData={step === (2) ? pathData : step === 3 ? pathTwoData : step === 4 ? stateThreeData : undefined }
					customThreeObject={d => {
						var parent = new THREE.Object3D()

						if (step === 3) {
							const line = lineMarker(Math.random() * 60);
							parent.add(line);
						} else if (d.marker) {
							parent.add(d.marker)
						} else {
							parent = null
						}

						return parent;
					}}
					customThreeObjectUpdate={(obj, d) => {
						Object.assign(obj.position, globeEl.current.getCoords(d.lat, d.lng, d.alt));
						var point = new THREE.Vector3(0, 0, 0);
						obj.lookAt(point);
						obj.rotateX(Math.PI / 2);
					}}
					rendererConfig={{ antialias: false }}
				/>
			</div>
		</>
	);
}

export default GlobeOne;
