import * as THREE from 'three'

const marker = () => {
	const _marker = new THREE.Object3D();
	const sphere = new THREE.Mesh(
		new THREE.SphereBufferGeometry(1),
		new THREE.MeshLambertMaterial({ color: 'white' })
	);
	const hex = new THREE.Mesh(
		new THREE.CylinderGeometry(4, 4, 1, 6, 1, true),
		new THREE.MeshLambertMaterial({ color: 'white', depthWrite: true, depthTest: true, side: THREE.DoubleSide })
	);

	hex.rotateX(Math.PI / 2);
	const alt = Math.random() * -54
	sphere.position.y = alt
	hex.position.y = alt
	_marker.add(sphere, hex)

	return _marker;
}

export default marker;
