import './App.css';
import GlobeOne from "./components/Globe";
import React, { useState, useEffect, useRef } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import ScrollIcon from './components/ScrollIcon'
import store from './state'

function App() {
	const container = useRef();
	const [activeIndex, setActiveIndex] = useState(0);

	const contentStore = [
		{
			title: "Digital Farming Around the Globe",
			content: "<b>Agriculture relies on data. It always has.</b> Rain, light, soil, seed performance: farmers have been measuring these data points since the first seeds were hand-spread into the earth. But with the aid of modern technology, they can see so much more.",
			rounded_text_one: "The Carbon Initiative",
			rounded_text_two: "Scaling Up Conservation",
			rounded_text_three: "Smallholder Initiatives",
			background_url: "section_1_bg.jpg"
		},
		{
			left_text: "At Bayer, we’re leading the digital transformation of agriculture around the world.",
			right_text: "Click to explore the ideas that are empowering farmers and forging sustainability.",
			background_url: "background_2.jpg"
		},
		{
			section_id: '01',
			title: "Sequestering Carbon",
			content: "In order to make truly sustainable changes in agriculture, climate-smart practices need to make sense financially. So, we’re helping make that a reality for farmers around the world. Through the Bayer Carbon Initiative Program, we’re empowering farmers to use digital tools to sequester carbon. <br><br>Farmers enrolled in the program are incentivized to adopt climate-smart practices like cover crops and conservation tillage, which they register on the FieldView platform. <br><br>The Bayer Carbon Program is quickly growing around the world. Click and drag the globe to see this expansion and hover over glowing regions to see how the program takes shape there.",
			background_url: "content-section-2-bg.jpg"
		},
		{
			section_id: '02',
			title: "Scaling Up Sustainability",
			content: "Because agriculture is rich with data, it generates massive amounts of useful information — and with it, massive opportunities to conserve. <br><br>The FieldView platform drives sustainability by giving farmers data they can act upon. The technology combines farmers’ own performance and historical data, public data such as weather, and data insights Bayer gathers on its products. Having all these streams of information in one place makes agronomic decisions more informed and more efficient. <br><br>FieldView has been bringing sustainable insights to North American farmers since 2015. By 2021, it expanded to farms in more than 20 countries.",
			background_url: "content-section-1-bg.jpg"
		},
		{
			section_id: '03',
			title: "Smallholder Initiatives",
			content: "Nearly two-thirds of the world’s smallholder farmers are located in Asia, and those farmers are responsible for over 60% of our global food production. Yet, because they are fragmented and often lack access to the marketplace, they can suffer from low productivity and instability. <br><br>So, we’ve advanced digital initiatives aimed at helping these farming families. Hover over the glowing countries to see how farmers there benefit.",
			background_url: "content-section-3-bg.jpg"
		},
		{
			title: "A world we can measure is a world we can understand.",
			content: "So, let’s look closely at what’s in front of us. Let’s use data to better understand agriculture and share what we find. Because the picture grows clearer when the data goes deep.",
			background_url: "footer-bg.jpg"
		}
	]

	const handleChange = (event) => {
		const dataIndex = parseInt(event.target.getAttribute('data-index'));
		if (!store.finalSteps.includes(dataIndex)) {
			store.finalSteps.push(dataIndex);
		} else {
			store.finalSteps.splice(store.finalSteps.indexOf(dataIndex), 1);
		}
	}

	const onLeave = (origin, destination, direction) => {
		// console.log(destination.index);
		setActiveIndex(parseInt(destination.index))
		store.step = parseInt(destination.index);
	}

	const afterLoad = (origin, destination, direction) => {
		if ((parseInt(destination.index) === 0 && direction === 'up') || (parseInt(origin.index) === 0 && direction === 'up')) {
			window.parent.postMessage('top', '*');
		} else if ((parseInt(destination.index) === 5 && direction === 'down') || (parseInt(origin.index) === 5 && direction === 'down')) {
			window.parent.postMessage('bottom', '*');
		} else {
			window.parent.postMessage('sticky', '*');
		}
	}

  return (
    <>
			<div className="panel-wrap" ref={container}>
				<ReactFullpage
					licenseKey={'8BA9DACD-E4424B1A-96B7AAE2-2244973F'}
					onLeave={onLeave}
					scrollingSpeed={1250}
					fitToSectionDelay={0}
					// css3={false}
					// autoScrolling={true}
					// setAllowScrolling={false}
					// setKeyboardScrolling={true}
					// afterRender={enableAutoScrolling}
					afterLoad={afterLoad}
					scrollOverflow={true}
					render={({ state, fullpageApi }) => {
						store.fullPage = fullpageApi;

						return (
							<ReactFullpage.Wrapper>
								{contentStore.map((section, index)=> {
									var textPath = '<textPath startOffset="51%" xlink:href="#curve">' + section.rounded_text_one + '</textPath>';
									var textPath2 = '<textPath startOffset="69%" xlink:href="#curve">' + section.rounded_text_two + '</textPath>';
									var textPath3 = '<textPath startOffset="87%" xlink:href="#curve">' + section.rounded_text_three + '</textPath>';

									return(
										<div className={"section section__" + index} data-index={index} key={index} >
											<div className="section__inner">
												<div className="content-wrap">
													{section.section_id && <h5>{section.section_id} - 04</h5>}
													{section.title && <h3 dangerouslySetInnerHTML={{ __html: section.title }}/>}
													{section.content && <p dangerouslySetInnerHTML={{ __html: section.content }} />}
													{section.left_text && <p className="left">{section.left_text}</p>}
													{section.right_text && <p className="right">{section.right_text}</p>}
												</div>

												<div className="mobile-spacer">
													<div className="mobile-spacer-top"></div>
													<div className="mobile-spacer-bottom"></div>
												</div>

												{activeIndex === 0 && <div className="shape-wrap">
													<svg viewBox="0 0 612 306">
														<path id="curve" fill="transparent" d="M612,306H0C0,137,137,0,306,0S612,137,612,306Z" />
														{section.rounded_text_one && <text textAnchor="middle" width="612" dangerouslySetInnerHTML={{ __html: textPath }} />}
														{section.rounded_text_two && <text textAnchor="middle" width="612" dangerouslySetInnerHTML={{ __html: textPath2 }} />}
														{section.rounded_text_three && <text textAnchor="middle" width="612" dangerouslySetInnerHTML={{ __html: textPath3 }} />}
													</svg>
												</div>}
											</div>
										</div>
									)
								})}
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</div>
			<div className="scroll-indicator" data-active={parseInt(activeIndex)}/>
			<div className="lower-gradient" data-active={parseInt(activeIndex)} />
			<div className="scroll-range-nav" data-active={parseInt(activeIndex)}>
				<label data-index={2} className="switch">
					<div className="label">The Carbon Initiative</div>
					<input type="checkbox" defaultChecked={true} data-index={2} onChange={handleChange} />
					<span className="slider round"></span>
				</label>
				<label data-index={3} className="switch">
					<div className="label">Scaling Up Conservation</div>
					<input type="checkbox" data-index={3} onChange={handleChange} />
					<span className="slider round"></span>
				</label>
				<label data-index={4} className="switch">
					<div className="label">Smallholder Initiatives</div>
					<input type="checkbox" data-index={4} onChange={handleChange}/>
					<span className="slider round"></span>
				</label>
			</div>
			<img className="cloud-overlay" alt="Cloud Overlay" src="clouds.png" data-active={parseInt(activeIndex)} />
			{/* {activeIndex === 1 && <div className="scroll-prompt">Scroll Down</div>} */}
			<ScrollIcon activeIndex={parseInt(activeIndex)} />
			<GlobeOne index={parseInt(activeIndex)} />
			{contentStore.map((section, index) => {
				return(
					<div className="background" key={index} data-index={parseInt(index)} data-active={parseInt(activeIndex)} style={{ background: 'url(' + section.background_url +') no-repeat center center / cover'}} />
				)
			})}
    </>
  );
}

export default App;
